<template>
  <div class="flightsDashboard js-tabBox flightsDashboard--provider">
    <div class="flightsDashboard__header">
      <router-link class="flightsDashboard__back" to="/provider-dashboard/flights">
        <span class="icon"><SvgIcon name="left-arrow"/></span>
        <span class="text">Back to all flights</span>
      </router-link>
    </div>
    <div class="flightsDashboard__content">
      <vue-scroll :ops="ops" class="flightsDashboard__content-left flights-detail-left-scroll">
        <div class="flightsDashboard__detail">
          <div class="flightsDashboard__detail-item">
            <div class="flightsDashboard__detail-item-caption">Flight confirmation number</div>
            <div class="flightsDashboard__detail-item-text">
              <span class="text">{{flight.flightsConfNumber}}</span>
            </div>
          </div>
          <div
            class="flightsDashboard__detail-item flightsDashboard__detail-item--slider flightsDashboard__detail-item--slider-detail">
            <div class="flightsDashboard__detail-item-caption">Aircraft and Aircraft photo</div>
            <div class="flightsDashboard__detail-item-text">
              <span class="text">{{flight.aircraftType}}</span>
            </div>
            <Slider :type="'vertical'" :thumsSlides="3"/>
          </div>
          <div class="flightsDashboard__detail-item flightsDashboard__detail-item--small">
            <div class="flightsDashboard__detail-item-caption">Flyer's name</div>
            <div class="flightsDashboard__detail-item-text">
              <span class="text">{{flight.name}}</span>
            </div>
          </div>
          <div class="flightsDashboard__detail-item flightsDashboard__detail-item--small">
            <div class="flightsDashboard__detail-item-caption">Booking number</div>
            <div class="flightsDashboard__detail-item-text">
              <span class="text">{{flight.bookingNumber}}</span>
            </div>
          </div>
          <div class="flightsDashboard__detail-item flightsDashboard__detail-item--small">
            <div class="flightsDashboard__detail-item-caption">Flyer's flight number</div>
            <div class="flightsDashboard__detail-item-text">
              <span class="text">{{flight.flyerNumber}}</span>
            </div>
          </div>
          <div class="flightsDashboard__detail-item flightsDashboard__detail-item--small">
            <div class="flightsDashboard__detail-item-caption">Booking date</div>
            <div class="flightsDashboard__detail-item-text">
              <span class="text">{{flight.bookingDate}}</span>
            </div>
          </div>
          <div class="flightsDashboard__detail-item">
            <div class="flightsDashboard__detail-item-caption">Origin (location)</div>
            <div class="flightsDashboard__detail-item-text">
              <span class="text">{{flight.origin}}</span>
            </div>
          </div>
          <div class="flightsDashboard__detail-item">
            <div class="flightsDashboard__detail-item-caption">Destination (location)</div>
            <div class="flightsDashboard__detail-item-text">
              <span class="text">{{flight.destination}}</span>
            </div>
            <div class="flightsDashboard__detail-item-button-wr">
              <div
                class="flightsDashboard__detail-item-button"
                :class="{'active' : flight.oneWay === true}"
              >One way
              </div>
              <div
                class="flightsDashboard__detail-item-button"
                :class="{'active' : flight.oneWay === false}"
              >Round trip
              </div>
            </div>
          </div>
        </div>
      </vue-scroll>
      <vue-scroll :ops="ops" class="flightsDashboard__content-right">
        <div class="flightsDashboard__detail">
          <div class="flightsDashboard__detail-item">
            <div class="flightsDashboard__detail-item-caption">Flight date and time - inbound</div>
            <div class="flightsDashboard__detail-item-text">
              <span class="text">{{flight.flightDateTime.date}}</span>
            </div>
            <div class="flightsDashboard__detail-item-text">
              <span class="text">{{flight.flightDateTime.est}}</span>
            </div>
            <div class="flightsDashboard__detail-item-text">
              <span class="text">{{flight.flightDateTime.gmt}}</span>
            </div>
          </div>
          <div class="flightsDashboard__detail-item">
            <div class="flightsDashboard__detail-item-caption">Depart</div>
            <div class="flightsDashboard__detail-item-text">
              <span class="text">{{flight.depart.title}}</span>
            </div>
            <div class="flightsDashboard__detail-item-text">
              <span class="text">{{flight.depart.date}}</span>
            </div>
            <div class="flightsDashboard__detail-item-text">
              <span class="text">{{flight.depart.est}}</span>
            </div>
            <div class="flightsDashboard__detail-item-text">
              <span class="text">{{flight.depart.gmt}}</span>
            </div>
          </div>
          <div class="flightsDashboard__detail-item">
            <div class="flightsDashboard__detail-item-caption">Land</div>
            <div class="flightsDashboard__detail-item-text">
              <span class="text">{{flight.land.title}}</span>
            </div>
            <div class="flightsDashboard__detail-item-text">
              <span class="text">{{flight.land.date}}</span>
            </div>
            <div class="flightsDashboard__detail-item-text">
              <span class="text">{{flight.land.est}}</span>
            </div>
            <div class="flightsDashboard__detail-item-text">
              <span class="text">{{flight.land.gmt}}</span>
            </div>
          </div>

          <div class="flightsDashboard__detail-item">
            <div class="flightsDashboard__detail-item-caption">Trip time (duration)</div>
            <div class="flightsDashboard__detail-item-text">
              <span class="text">Outbound</span>
            </div>
            <div class="flightsDashboard__detail-item-text">
              <span class="text">{{flight.tripTime.outbound}}</span>
            </div>
          </div>

          <div class="flightsDashboard__detail-item">
            <div class="flightsDashboard__detail-item-text">
              <span class="text">Inbound</span>
            </div>
            <div class="flightsDashboard__detail-item-text">
              <span class="text">{{flight.tripTime.inbound}}</span>
            </div>
          </div>

          <div class="flightsDashboard__detail-item">
            <div class="flightsDashboard__detail-item-caption">Aircraft operator</div>
            <div class="flightsDashboard__detail-item-text">
              <span class="text">{{flight.operator}}</span>
            </div>
          </div>

          <div class="flightsDashboard__detail-item flightsDashboard__detail-item--users">
            <div class="flightsDashboard__detail-item-caption">FlyJets contact person</div>
            <div class="flightsDashboard__users">
              <router-link
                v-for="item of flight.flyJetsPerson"
                :key="item.id"
                class="flightsDashboard__users-item"
                to="#"
              >
                <div
                  class="flightsDashboard__users-avatar"
                  :style="{backgroundImage: `url(${item.photo})`}"
                >
                </div>
                <div class="flightsDashboard__users-info">
                  <div class="flightsDashboard__users-name">{{item.name}}</div>
                  <div class="flightsDashboard__users-phone">{{item.phone}}</div>
                </div>
              </router-link>
            </div>
          </div>

          <div class="flightsDashboard__detail-item flightsDashboard__detail-item--users">
            <div class="flightsDashboard__detail-item-caption">
              Passengers list <span>(if applicable)</span>
            </div>
            <div class="flightsDashboard__users">
              <router-link
                v-for="item of flight.passengers"
                :key="item.id"
                class="flightsDashboard__users-item"
                to="#"
              >
                <div
                  class="flightsDashboard__users-avatar"
                  :style="{backgroundImage: `url(${item.photo})`}"
                >
                </div>
                <div class="flightsDashboard__users-info">
                  <div class="flightsDashboard__users-name">{{item.name}}</div>
                  <div class="flightsDashboard__users-phone">{{item.phone}}</div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </vue-scroll>
    </div>
  </div>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import Slider from '@/components/dashboard/Slider/Slider'
  import './FlightsProvider.styl'

  export default {
    name: 'FlightsProviderDetail',
    data: () => ({
      ops: {
        rail: {
          size: '3px',
          gutterOfSide: '0',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
    }),
    computed: {
      flight () {
        return this.$store.state.flightsProvider.flightDetail
      },
    },
    components: {
      SvgIcon,
      Slider,
    },
  }
</script>
